import * as React from 'react';
import PersonalArticleWrapper from './style';
import Posts from './Posts';
import Contact from '../Contact';

type PersonalArticleProps = {};

const PersonalArticle: React.FunctionComponent<PersonalArticleProps> = ({ ...props }) => {
	return (
		<PersonalArticleWrapper {...props}>
			{/* <SimpleSwiper /> */}
			<Posts />

			<div id="#contact">
				<Contact />
			</div>
		</PersonalArticleWrapper>
	);
};

export default PersonalArticle;
