import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Masonry from 'react-masonry-component';
import Button from '../../../common/Button/Button';
import ArticleListWrapper, { PostRow, PostCol, SecTitle, LoadMoreButton } from './style';
import ArticleItem from './ArticleItem';

type PostsProps = {};

const Posts: React.FunctionComponent<PostsProps> = () => {
	const Data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
				}
			}
			allSitePage(filter: { path: { eq: "/page/1" } }) {
				nodes {
					context {
						numPages
						currentPage
					}
				}
			}
			allMarkdownRemark(
				filter: { fileAbsolutePath: { regex: "/article/" } }
				sort: { fields: [frontmatter___date], order: DESC }
			) {
				totalCount
				edges {
					node {
						excerpt(pruneLength: 120)
						fields {
							slug
							readingTime {
								text
							}
						}
						frontmatter {
							date(formatString: "MMM DD, YYYY")
							title
							description
							protected
							tags
							cover {
								childImageSharp {
									fluid(maxWidth: 570, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	const Posts = Data.allMarkdownRemark.edges;
	const TotalPost = Data.allMarkdownRemark.edges.length;

	const [ state, setState ] = useState({
		visibile: 8
	});

	const [ load, setload ] = useState({
		loading: false
	});

	const fetchMoreData = () => {
		setload({ loading: true });

		setTimeout(function () {
			setState((prev) => {
				return { visibile: prev.visibile + 8 };
			});
			setload({ loading: false });
		}, 1000);
	};

	return (
		<ArticleListWrapper>
			<PostRow>
				<Masonry>
					{Posts.slice(0, state.visibile).map(({ node }: any) => {
						let link = '/article' + node.fields.slug;

						const title = node.frontmatter.title || node.fields.slug;

						return (
							<PostCol key={node.fields.slug}>
								<ArticleItem
									title={title}
									description={node.frontmatter.description || node.excerpt}
									url={link}
								/>
							</PostCol>
						);
					})}
				</Masonry>
				<LoadMoreButton>
					{state.visibile < TotalPost ? (
						<Button
							title="Load more"
							type="submit"
							onClick={fetchMoreData}
							isLoading={load.loading == true ? true : false}
							loader="Loading.."
						/>
					) : (
						<p>No more posts</p>
					)}
				</LoadMoreButton>
			</PostRow>
		</ArticleListWrapper>
	);
};

export default Posts;
